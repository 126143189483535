import React, { memo, useEffect, useState } from "react";
import { httpCrm } from "../../../services/apiService/apiService";
import { toast } from "react-toastify";
import $ from "jquery";
import { Link, useHistory } from "react-router-dom";
import ListMarketingCard from "./ListViewMarketingCard";
import { getSingleRoute } from "../../../../common/utils";
import editIcon from "../../../../static/images/special-edit.png";
import { slugSet } from "../../../common/getQueryString";
import purpleHeartIcon from "../../../../static/images/fav-purple.svg";
import yellowHeartIcon from "../../../../static/images/yellow_heart.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { addToFavouriteReq } from "../../../services/apiService";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import LoginModal from "../../LoginModal";

function ListViewDataRendering(props) {
  const {
    key,
    item,
    handleModalOpen,
    onClickApplyForm,
    commaSeparators,
    relevantCoursesClick,
    openDrawer,
    data,
    oldFav,
  } = props;

  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  let favCourses = item.favouriteCourses;
  const user = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  let variable = item.data;

  useEffect(() => {
    if (favCourses) {
      favCourses.map((subItem) => {
        if (subItem.id === item.id) {
          setIsFavorite(true);
        }
      });
    }
  }, []);

  const heartClick = async (item) => {
    console.log("Heart icon clicked");
    if (user.isAuthenticated) {
      setIsFavoriteLoading(true);
      try {
        let body = {
          course_id: item.course_id,
          is_deleted: isFavorite,
        };
        const { data: fav } = await addToFavouriteReq(body);
        if (fav.message) {
          enqueueSnackbar(fav.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        } else if (isFavorite) {
          setIsFavorite(!isFavorite);
          enqueueSnackbar("Course removed from favorite list successfully.", {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        } else {
          setIsFavorite(!isFavorite);
          enqueueSnackbar("Course added to favorite list successfully.", {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "center" },
          });
        }
      } catch (err) {
        console.log("Error:", err);
      }
      setIsFavoriteLoading(false);
    } else {
      setOpenLoginModal(true);
    }
  };
  const handleOpenLoginModal = () => {
    if (user.isAuthenticated) {
      window.open(
        "https://timesconsultant.com/online-consultation/",
        "_blank",
        "rel=noopener noreferrer"
      );
    } else {
      setOpenLoginModal(true);
    }
  };
  const handleCloseLoginModal = () => {
    setOpenLoginModal(false);
  };

  function capitalFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  const getRoute = async (page, item) => {
    const route = await getSingleRoute(page);
    return `${route}/${item.id}/${item.duration_id}/${item.course_fee_id}`;
  };

  const handleInstituteClick = async (item) => {
    const url = await getRoute("course_detail", {
      id: item.course_slug,
      duration_id: item?.duration_id,
      course_fee_id: item?.course_fee_id,
    });
    window.open(url, "_blank");
  };
  const slugSet = (str) => {
    return str
      .toLowerCase()
      .replace(/[^\w]+/g, "-")
      .replace(/-+/g, "-");
  };

  return (
    <>
      {item.name ? (
        <div className="universityList">
          <div className="imgWrap">
            <a
              href={`/university-details/${slugSet(item?.institute_name)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={item?.logo} alt={item?.institute_name} />
            </a>
          </div>
          <div className="contentWrap">
            <div className="topBtnGroup">
              <button
                className="btn share"
                onClick={() => handleModalOpen(item)}
              >
                <img
                  src={
                    require("../../../../static/images/share-purple.svg")
                      .default
                  }
                  alt="share-purple"
                />
              </button>

              <button
                className="btn"
                onClick={() => heartClick(item)}
                disabled={isFavoriteLoading}
              >
                {isFavoriteLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : isFavorite ? (
                  <img src={yellowHeartIcon} alt="yellow_heart" />
                ) : (
                  <img src={purpleHeartIcon} alt="fav-purple" />
                )}
              </button>
              <a
                href="#editModal"
                data-toggle="modal"
                title="Suggest an Edit"
                data-target="#editModal"
                onClick={() => {
                  localStorage.setItem("EditModal", true);
                }}
              >
                <img src={editIcon} alt="special-edit" />
              </a>
            </div>
            <h3 className="course-title">{item.name} </h3>
            <h4 className="d-inline" onClick={() => handleInstituteClick(item)}>
              {item.institute_name + " - "}
            </h4>
            <h4 className="d-inline">{item?.campus?.campus}</h4>
            <ul className="mt-2">
              <li style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    require("../../../../static/images/Specialization1.svg")
                      .default
                  }
                  alt="Specialization1"
                />
                <span style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                  {item.specialization_name}
                </span>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    require("../../../../static/images/bachelor_honors.svg")
                      .default
                  }
                  alt="bachelor_honors"
                />
                <span>
                  {item?.degreelevel_name} | {item?.coursetitle_name}
                </span>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require("../../../../static/images/English.svg").default}
                  alt="English"
                />
                <span>{capitalFirstLetter(item.course_language)}</span>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    require("../../../../static/images/full_time.svg").default
                  }
                  alt="full_time"
                />
                <span>Full Time</span>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require("../../../../static/images/Years.svg").default}
                  alt="Years"
                />
                <span>{item.duration}</span>
              </li>
              <li style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={require("../../../../static/images/Amount.svg").default}
                  alt="Amount"
                />
                <span>
                  {item?.course_fee
                    ? `${item.course_fee} ${item.currency}/Year`
                    : "To Be Confirmed"}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ) : item.card_type === "image_card" && item.list_card_img_back ? (
        <ListMarketingCard detail={item} />
      ) : null}
      <LoginModal
        openModal={openLoginModal}
        closeModal={handleCloseLoginModal}
      />
    </>
  );
}

export default memo(ListViewDataRendering);
