import React, { useState, useRef, useEffect } from "react";
import { Close } from "@material-ui/icons";
import { IconButton } from "@mui/material";


function BetaHeader() {
  const [isVisible, setIsVisible] = useState(true);
  const bannerRef = useRef(null);

  const handleClose = () => {
    sessionStorage.setItem('betaBarClosed', 'true');
    setIsVisible(false);
  };

  const handleSwitchToBeta = () => {
    window.location.href = "https://beta.timescoursefinder.com/";
  };

  useEffect(() => {
    const modalClosed = sessionStorage.getItem('betaBarClosed');
    if (modalClosed) {
      handleClose()
    }
  }, [isVisible]);
  return (
    isVisible && (
      <div className="betaTop" ref={bannerRef}>
        <div className="container-fluid d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center">
            <img
              src={require("../../../static/images/betaTCF.svg").default}
              alt="Beta Icon"
              className="betaIcon"
            />
            <p className="betaText pl-3 mb-0">
              Discover <span className="betaGradient">what’s new!</span> Experience the <span className="betaGradient">Beta Version</span> now.            
            </p>
          </div>
          <div>
            <button className="btnBeta" onClick={handleSwitchToBeta}>Switch to Beta</button>

          </div>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: '20px',
                display: { xs: 'none', sm: 'flex' }
              }}
            >
              <Close />
            </IconButton>
        </div>
      </div>
    )
  );
}

export default BetaHeader;