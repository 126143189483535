import { CheckCircle } from "@material-ui/icons";
import { Avatar, Box, Grid, Typography, useMediaQuery } from "@mui/material";
import ProgressBar from "../../../components/ProgressBar";
import { styles } from "../constants";
import dummyUser from "../../../../static/images/dummy-user-white.png";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const ProfileTop = () => {
  const outerTheme = useTheme();
  const isMobile = useMediaQuery(outerTheme.breakpoints.down("sm"));
  const { user } = useSelector((state) => state.auth);

  return (
    <Box
      component="div"
      sx={isMobile ? styles?.withBgImgMobile : styles?.withBgImg}
    >
      <Grid container spacing={1} sx={styles?.gridContainer}>
        <Grid item xs={12} sx={styles?.gridItem} >
          <div style={{ paddingLeft: isMobile ? "10px" : "40px" }}>
            <Typography variant={isMobile ? "h6" : "h4"} className="text-white">
              Complete Your Profile
            </Typography>
            {isMobile ? (
              <>
                <p className="text-white mb-0">
                  to be eligible for a free online
                </p>
                <p className="text-white mb-0">counseling session</p>
              </>
            ) : (
              <p className="text-white mb-0">
                to be eligible for a free online counseling session
              </p>
            )}
          </div>
        </Grid>
        <Grid

         
          item
          xs={6}
          sx={styles.gridItem}
          style={{ paddingLeft: isMobile ? "30px" : "60px" }}
        >
          <div className="d-flex align-items-center w-100 mb-5">
            <div>
              <Avatar
                alt="Display Picture"
                src={user?.picture || dummyUser}
                sx={{ width: isMobile ? 50 : 100, height: isMobile ? 50 : 100 }}
                className="border rounded-circle"
              />
            </div>
            <div className="pl-3 pb-2 w-100">
              <div>
                <CheckCircle style={{ color: "#8ECC3B" }} />
                <h6 className="d-inline text-white pl-2">
                  {user?.userDetails?.profileCompletion || 60}% Completed
                </h6>
              </div>
              <ProgressBar
                variant="determinate"
                value={user?.userDetails?.profileCompletion || 60}
              />
              {user?.userDetails?.profileCompletion === "100" && (
                <div className="pt-2">
                  <p className="text-white">
                    <u
                      className="font-weight-bold text-white cursor-pointer"
                      onClick={() =>
                        window.open(
                          "https://timesconsultant.com/online-consultation/",
                          "_blank",
                          "rel=noopener noreferrer"
                        )
                      }
                    >
                      Redeem
                    </u>{" "}
                    your free online counseling session now!
                  </p>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileTop;
