import React, { useEffect, useState } from "react";
import Select from "react-select";
import Slider from "react-slick";
import * as a from "../../apis/store/actions";
import { useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import MainSearch from "../../components/search/main_search";
import LoadingSpinner from "../../components/loader/loader";
import { getSingleRoute } from "../../../common/utils";
import { SwitchToBetaModal } from "../../components/switch-beta-modal/BetaModal";

var leadingSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                centerMode: false,
                infinite: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                infinite: true,
            },
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1,
                infinite: true,
            },
        },
        {
            breakpoint: 481,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};
const noRecord = "No Record Found!";
function Landing(props) {
    const { history } = props
    const dispatch = useDispatch();
    const pathwayGroupList = useSelector(
        ({ apis }) => apis.allPathwayGroups.data
    );
    const { instituteGroupData,
        instituteGroupLoading } = useSelector(
            ({ apis }) => apis.allInstituteGroups
        );
    const countryScholarships = useSelector(
        ({ apis }) => apis.scholarshipRelatedCountries.data
    );

    const applyPortalList = useSelector(({ apis }) => apis.allApplyPortals.data);

    const [pathwayGroupList1, setpathwayGroupList1] = useState(pathwayGroupList);
    const [pathwayLoading, setPathwayLoading] = useState(true);
    const [applyPortals, setApplyPortals] = useState(applyPortalList);
    const [applyPortalsLoading, setApplyPortalsLoading] = useState(true);
    const [countryData, setCountryData] = useState(countryScholarships);
    const [countryLoading, setCountryLoading] = useState(true);
    const [isBetaModalOpen, setIsBetaModalOpen] = useState(true);
    const handleCloseBetaModal = () => {
        sessionStorage.setItem('betaModalClosed', 'true');
        setIsBetaModalOpen(false);
    };


    useEffect(() => {
        dispatch(a.getAllPathwayGroups()).then(() => {
            setPathwayLoading(false);
        });
        dispatch(a.getAllInstituteGroups());
        dispatch(a.getScholarshipRelatedCountries()).then(() => {
            setCountryLoading(false);
        })
        dispatch(a.getAllApplyPortals()).then(() => {
            setApplyPortalsLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        if (pathwayGroupList) {
            setpathwayGroupList1(pathwayGroupList);
        }
        if (countryScholarships) {
            setCountryData(countryScholarships);
        }
        if (applyPortalList) {
            setApplyPortals(applyPortalList);
        }
    }, [pathwayGroupList, countryScholarships, applyPortalList]);
    const getRoute = (page, id) => {
        getSingleRoute(page).then(d => history.push({ pathname: d + `${id?.id ? '/' + id?.id : ''}`, search: id?.search }))
    }

      useEffect(() => {
        const modalClosed = sessionStorage.getItem('betaModalClosed');
        if (modalClosed) {
            handleCloseBetaModal()
        }
      }, [isBetaModalOpen]);
    return (
        <div className="main homePage">
            <SwitchToBetaModal open={isBetaModalOpen} handleClose={handleCloseBetaModal} />

            <MainSearch />
            {/* <section className="locationSec">
                <MyMap/>
            </section> */}
            <section className="scholarshipSec">
                <div className="container">
                    <div className="row d-block text-center">
                        <h3>APPLICATION PORTAL FOR FUTURE LEADERS</h3>
                        <p>COMPARE & APPLY TO YOUR PROGRAM & INSTITUTES</p>
                    </div>
                    <section className="futureLeadSec pt-2">
                        <div className="left">

                            <img
                                src="https://assets.timescoursefinder.com/Media/future-leader.svg"
                                alt={'future-leader'}
                            />
                        </div>
                        <div className="right">
                            {applyPortalsLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <div>
                                    {applyPortals.length ? (
                                        <div>
                                            <div className="logoFlex">
                                                {applyPortals.map((item, index) => {
                                                    if (index < 4) {
                                                        return (
                                                            <button
                                                                onClick={() => getRoute('apply_portal_universities', { id: item.slug })}
                                                                // to={{
                                                                //     pathname: "/apply-portal-universities/",
                                                                //     search: $.param({
                                                                //         app_id: item.id,
                                                                //         app_name: item.name,
                                                                //     }),
                                                                // }}
                                                                className="flip-card logoBox"
                                                            >
                                                                <div className="flip-card-inner">
                                                                    <div className="flip-card-front">
                                                                        <img src={item.logo} alt={item.name} />
                                                                    </div>
                                                                    <div className="flip-card-back">
                                                                        <div className="content">
                                                                            <img src={item.logo} alt={item.name} />
                                                                            <p>{item.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        );
                                                    }
                                                })}
                                            </div>
                                            <button
                                                onClick={() => getRoute('all_apply_portals')}
                                                className="btn"
                                            // to={`/all-apply-portals`}
                                            >
                                                View all
                                            </button>
                                        </div>
                                    ) : (
                                        <p>{noRecord}</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </section>
                </div>

            </section>
            <section className="leadingWorldSec">
                <div className="container">
                    <h3>Your Quick Book To Explore </h3>
                    <h4>Leading World Universities</h4>
                    {instituteGroupLoading ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            {instituteGroupData?.results?.length ? (
                                <div>
                                    <Slider {...leadingSettings}>
                                        {instituteGroupData?.results?.map((item, index) => (
                                            <div>
                                                {/* <button
                                                    // className="leadBox"
                                                    onClick={()=>getRoute('group_universities',{id:item.id})}
                                                    // to={"/group-universities/" + item.id}
                                                > */}
                                                <button
                                                    type="button"
                                                    onClick={() => getRoute('group_universities', { id: item.slug })}
                                                    // to={"/group-universities/" + item.id}
                                                    className="leadBox"
                                                >
                                                    <img src={item.logo} alt={item.name} />
                                                </button>
                                                {/* </button> */}
                                            </div>
                                        ))}
                                    </Slider>
                                    <a
                                        onClick={() => getRoute('all_groups')}
                                        href
                                        className="btn viewAllBtn">
                                        View all
                                    </a>
                                </div>
                            ) : (
                                <p> {noRecord} </p>
                            )}
                        </div>
                    )}
                </div>
            </section>

            <div className="container">
                <div className="row d-block text-center pt-5">
                    <h2>Discover Scholarships Across the World</h2>
                    <p>
                        We have thousands of Scholarships offered at your favourite study
                        destinations.
                    </p>
                </div>
                {countryLoading ? (
                    <LoadingSpinner />
                ) : (
                    <div>
                        {countryData.length ? (
                            <div>
                                <div className="scholarshipTop">
                                    {countryData.map((item, index) => {
                                        if (index < 2) {
                                            return (
                                                <div className="flip-card scolarshipCard">
                                                    <div className="flip-card-inner">
                                                        <div className="flip-card-front">
                                                            <img src={item.scholarship_logo} alt={item.name} />
                                                            <h2>
                                                                {item.scholarship_count} Scholarships in{" "}
                                                                {item.name}{" "}
                                                            </h2>
                                                        </div>
                                                        <div className="flip-card-back">
                                                            <div className="content">
                                                                {/*<h3>Win a €5000 </h3>*/}
                                                                <h4>Scholarship to study in {item.name}</h4>
                                                                {/*<a href="/scholarships" className="btn defaultBtn">View More</a>*/}
                                                                {/* <Link
																		className="btn defaultBtn"
																		// to={`/scholarships/${item.id}/${1}`}
																		// to={`/scholarships`}
																	> */}
                                                                <button
                                                                    className="btn defaultBtn"
                                                                    onClick={() => getRoute('scholarships', { search: `?country=${item.slug}&filters=true` })}
                                                                // to={{
                                                                //     pathname: "/scholarships",
                                                                //     search: $.param({
                                                                //         id: item.id,
                                                                //         name: item.name,
                                                                //         filters:true,
                                                                //     }),
                                                                // }}
                                                                >
                                                                    View More
                                                                </button>
                                                                {/* View More
																	</Link> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                <div className="scholarshipBottom">
                                    {countryData.map((item, index) => {
                                        if (index >= 2 && index < 7) {
                                            return (
                                                <div className="flip-card scolarshipCard">
                                                    <div className="flip-card-inner">
                                                        <div className="flip-card-front">
                                                            <img src={item.scholarship_logo} alt={item.name} />
                                                            <h2>
                                                                {item.scholarship_count} Scholarships in{" "}
                                                                {item.name}{" "}
                                                            </h2>
                                                        </div>
                                                        <div className="flip-card-back">
                                                            <div className="content">
                                                                <button
                                                                    className="btn defaultBtn"
                                                                    onClick={() => getRoute('scholarships', {
                                                                        search: `?country=${[item.slug]}&filters=true`
                                                                        // $.param({
                                                                        //     id: item.slug,
                                                                        //     name: item.name,
                                                                        //     filters:true,
                                                                        // })
                                                                    })}
                                                                // to={{
                                                                //     pathname: "/scholarships",
                                                                //     search: $.param({
                                                                //         id: item.id,
                                                                //         name: item.name,
                                                                //         filters:true,
                                                                //     }),
                                                                // }}
                                                                >
                                                                    View More
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                <button
                                    onClick={() => getRoute('scholarship_country')}
                                    // href="/scholarship-country" 
                                    className="btn seeAllContBtn">
                                    See all Scholarships<i className="fa fa-angle-right"></i>
                                </button>
                            </div>
                        ) : (
                            <p className="text-center"> {noRecord}</p>
                        )}
                    </div>
                )}
            </div>

            {/* <section className="topRankedSec">
				<div className="container">
					<h5>Find a Perfect Way to lead top ranked universities</h5>
					<p>COMPARE & APPLY TO YOUR PROGRAM</p>
					{pathwayLoading ? (
						<LoadingSpinner />
					) : (
						<div>
							{pathwayGroupList1.length ? (
								<div>
									<div className="logoFlex">
										{pathwayGroupList1.map((item, index) => {
											if (index < 4) {
												return (
													<a
														href={"/pathway-group-universities/" + item.id}
														className="flip-card logoBox"
													>
														<div className="flip-card-inner">
															<div className="flip-card-front">
																<img src={item.logo} alt="banner" />
															</div>
															<div className="flip-card-back">
																<div className="content">
																	<img src={item.logo} alt="banner" />
																	<p>{item.name}</p>
																</div>
															</div>
														</div>
													</a>
												);
											}
										})}
									</div>
								</div>
							) : (
								<p>{noRecord}</p>
								// <LoadingSpinner />
							)}
						</div>
					)}
					<div>
						{pathwayGroupList1.length ? (
							<div>
								<div className="topRankedLogoFlex">
									<div className="logoFlex ">
										{pathwayGroupList1.map((item, index) => {
											if (index > 3 && index < 6) {
												return (
													<a
														href={"/pathway-group-universities/" + item.id}
														className="flip-card logoBox"
													>
														<div className="flip-card-inner">
															<div className="flip-card-front">
																<img src={item.logo} alt="banner" />
															</div>
															<div className="flip-card-back">
																<div className="content">
																	<img src={item.logo} alt="banner" />
																	<p>{item.name}</p>
																</div>
															</div>
														</div>
													</a>
												);
											}
										})}
									</div>
									<div className="topRankBtn">
										<Link className="btn" to={"all-pathway-groups"}>
											<span>View all</span>
										</Link>
									</div>
								</div>
							</div>
						) : (
							// <p>{noRecord}</p>
							<LoadingSpinner />
						)}
					</div>
				</div>
			</section> */}
            <div className="container">
                <section className="studyAbroadSec">
                    <h3>Study Abroad Journey </h3>
                    <p>Simplified Step By Step</p>
                    <div className="studyAbroadFlex">
                        <div className="studyAbroadBox">
                            <div>
                                <img
                                    src={require("../../../static/images/abroad-01.svg").default}
                                    alt={'Choose Program & Institute'}
                                />
                            </div>
                            <h6>Choose Program & Institute</h6>
                        </div>
                        <div className="studyAbroadBox">
                            <div>
                                <img
                                    src={require("../../../static/images/abroad-02.svg").default}
                                    alt={'Documents Preparation'}
                                />
                            </div>
                            <h6>Documents Preparation</h6>
                        </div>
                        <div className="studyAbroadBox">
                            <div>
                                <img
                                    src={require("../../../static/images/abroad-03.svg").default}
                                    alt={'Application Submission'}
                                />
                            </div>
                            <h6>Application Submission</h6>
                        </div>
                        <div className="studyAbroadBox">
                            <div>
                                <img
                                    src={require("../../../static/images/abroad-04.svg").default}
                                    alt="Receiving Acceptance"
                                />
                            </div>
                            <h6>Receiving Acceptance</h6>
                        </div>
                        <div className="studyAbroadBox">
                            <div>
                                <img
                                    src={require("../../../static/images/abroad-05.svg").default}
                                    alt="Getting your Visa"
                                />
                            </div>
                            <h6>Getting your Visa</h6>
                        </div>
                        <div className="studyAbroadBox">
                            <div>
                                <img
                                    src={require("../../../static/images/abroad-06.svg").default}
                                    alt="Departure"
                                />
                            </div>
                            <h6>Departure</h6>
                        </div>
                        <div className="studyAbroadBox">
                            <div>
                                <img
                                    src={require("../../../static/images/abroad-07.svg").default}
                                    alt="Accommodation Arrangement"
                                />
                            </div>
                            <h6>Accommodation Arrangement</h6>
                        </div>
                    </div>
                </section>
            </div>
            <section className="counselSec">
                <div className="container">
                    <h5>Our Counselling Services</h5>
                    <div className="counselFlex">
                        <div className="counselBox">
                            <img
                                src={require("../../../static/images/counsel1.svg").default}
                                alt="Personalized Counseling Document"
                            />
                            <h6>Personalized Counseling Document</h6>
                        </div>
                        <div className="counselBox">
                            <img
                                src={require("../../../static/images/counsel2.svg").default}
                                alt="SOP, LORs Guidance"
                            />
                            <h6>SOP, LORs Guidance</h6>
                        </div>
                        <div className="counselBox">
                            <img
                                src={require("../../../static/images/counsel3.svg").default}
                                alt="Instant Chat Availability"
                            />
                            <h6>Instant Chat Availability</h6>
                        </div>
                        <div className="counselBox">
                            <img
                                src={require("../../../static/images/counsel4.svg").default}
                                alt="Student Admission Portal"
                            />
                            <h6>Student Admission Portal</h6>
                        </div>
                        <div className="counselBox">
                            <img
                                src="https://assets.timescoursefinder.com/Media/counsel5.svg"
                                alt="Visa Counseling"
                            />
                            <h6>Visa Counseling</h6>
                        </div>
                    </div>
                    <button
                        className="btn"
                        // onClick={() => { $("#ApplyNow").modal("show");}}
                        onClick={() => window.open('https://timesconsultant.com/online-consultation/', '_blank', 'rel=noopener noreferrer')}
                    >
                        Need Assistance
                    </button>
                </div>
            </section>
            {/* <div
                className="modal fade signinModal whyFormModal"
                id="ApplyNow"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <ApplyNowModal />
            </div> */}
            <div
                className="modal fade signinModal editModal"
                id="editModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                id="login_modal_close_btn"
                                aria-label="Close"
                            >
                                <img
                                    src={
                                        require("../../../static/images/modal-times.svg").default
                                    }
                                    alt="modal-times"
                                />
                            </button>
                            <h3>Suggest an Edit or Post Review</h3>
                            <p>
                                Studied or Worked here? Share your Review or suggest editing
                            </p>
                            <form>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="First Name"
                                                className="form-control"
                                            ></input>
                                            <img
                                                src={
                                                    require("../../../static/images/forms/full_name.svg")
                                                        .default
                                                }
                                                alt="full_name"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Last Name"
                                                className="form-control"
                                            ></input>
                                            <img
                                                src={
                                                    require("../../../static/images/forms/full_name.svg")
                                                        .default
                                                }
                                                alt="full_name"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <Select
                                                name="filters"
                                                placeholder={"Phone Type"}
                                                isSearchable={false}
                                                theme={(theme) => ({
                                                    ...theme,
                                                })}
                                            />

                                            <img
                                                src={
                                                    require("../../../static/images/forms/phone.svg")
                                                        .default
                                                }
                                                alt="phone"
                                                height=""
                                                width=""
                                                className="formIcon"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md">
                                        <div className="form-group">
                                            <PhoneInput
                                                autoFormat
                                                country={"pk"}
                                                placeholder="Enter the Number"
                                                enableAreaCodes="true"
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        className="form-control"
                                    ></input>
                                    <img
                                        src={
                                            require("../../../static/images/forms/Email.svg").default
                                        }
                                        alt="Email"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                </div>

                                <div className="form-group">
                                    <textarea
                                        placeholder="Comment"
                                        className="form-control"
                                    ></textarea>
                                    <img
                                        src={
                                            require("../../../static/images/forms/comment.svg")
                                                .default
                                        }
                                        alt="comment"
                                        height=""
                                        width=""
                                        className="formIcon"
                                    />
                                </div>
                            </form>
                            <h6>Please do not post: </h6>
                            <ul>
                                <li>Aggressive or discriminatory language</li>
                                <li>Profanities</li>
                                <li>Trade secrets or confidential information</li>
                            </ul>
                            <p>
                                We appreciate you for doing your part to Keep Times Course
                                Finder most trusted education portal
                            </p>
                            <div className="btnGroup">
                                <button className="btn defaultBtn">Add Review</button>
                                <button className="btn primaryBtn">Suggest Editing</button>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default Landing;
