import React, { useEffect } from "react";
import { Modal, Box, useMediaQuery, IconButton } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import linePattern from "../../../static/images/illustrations/LinesPattern.png";
import { Close } from "@material-ui/icons";
import { useTheme } from "@mui/material/styles";
import betaImageOne from "../../../data/images/betaImageOne.png";
import betaImageTwo from "../../../data/images/betaImageTwo.png";
import betaImageThree from "../../../data/images/betaImageFour.png";
import betaImageFour from "../../../data/images/betaImageFive.png";
const images = [betaImageOne, betaImageTwo, betaImageThree, betaImageFour];

export const SwitchToBetaModal = ({ open, handleClose }) => {
  const outerTheme = useTheme();
  const isMobile = useMediaQuery(outerTheme.breakpoints.down("sm"));
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleSwitchToBeta = () => {
    window.location.href = "https://beta.timescoursefinder.com/";
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "90%" : "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "15px",
          backgroundImage: `url(${linePattern})`,
          backgroundSize: "contain",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundOrigin: "content-box",
          outline: "none",
          "&:focus": { outline: "none" },
          "&:focus-visible": { outline: "none" },
          "&:focus-within": { outline: "none" }
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8 }}
        >
          <Close />
        </IconButton>
        <Box sx={{ display: "flex", justifyContent: "center", pt: 5, pb: 2 }}>
          <Box sx={{ width: isMobile ? "90%" : "60%" }}>
            {" "}
            <Slider {...settings}>
              {images.map((img, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <img
                    src={img}
                    alt={`Slide ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      borderRadius: 8,
                    }}

                  />
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
        <Box sx={{
          mt: 2,
          textAlign: "start",
        }}>
          <h2 className="text-center">Be the first to use our <span style={{
            background: 'linear-gradient(to right, #6A11CB, #2575FC)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',

          }}>Beta Version</span></h2>
          <p
            style={{
              fontSize: isMobile ? "16px" : "18px",
              color: "#656565",
              fontWeight: "600",
              lineHeight: "1.3",
              textAlign: "center",
            }}
          >
            We’ve reimagined everything! Experience our new version in advance and get a first look at what’s ahead! You can always switch back.
          </p>

          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <button
              onClick={handleSwitchToBeta}
              style={{
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: "500",
                lineHeight: "1",
                padding: isMobile ? "10px 12px 10px 5px" : "10px 14px 10px 5px",
                backgroundColor: "#5551FF",
                borderRadius: "86px",
                border: "none",
                outline: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: "180px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                cursor: "pointer",
              }}
            >
              <svg width="31" height="30" viewBox="0 0 37 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 8.75L12.355 10.4925C11.51 12.7775 11.0875 13.92 10.2537 14.7538C9.42 15.5875 8.2775 16.01 5.9925 16.855L4.25 17.5L5.9925 18.145C8.2775 18.99 9.42 19.4138 10.2537 20.2463C11.0875 21.0788 11.51 22.2225 12.355 24.5075L13 26.25L13.645 24.5075C14.49 22.2225 14.9137 21.08 15.7462 20.2463C16.5787 19.4125 17.7225 18.99 20.0075 18.145L21.75 17.5L20.0075 16.855C17.7225 16.01 16.58 15.5875 15.7462 14.7538C14.9125 13.92 14.49 12.7775 13.645 10.4925L13 8.75ZM23 3.75L22.7237 4.49625C22.3612 5.47625 22.18 5.96625 21.8238 6.3225C21.4663 6.68 20.9763 6.86125 19.9963 7.2225L19.25 7.5L19.9975 7.77625C20.9762 8.13875 21.4663 8.32 21.8225 8.67625C22.18 9.03375 22.3612 9.52375 22.7225 10.5037L23 11.25L23.2763 10.5037C23.6388 9.52375 23.82 9.03375 24.1762 8.6775C24.5337 8.32 25.0237 8.13875 26.0037 7.7775L26.75 7.5L26.0025 7.22375C25.0238 6.86125 24.5337 6.68 24.1775 6.32375C23.82 5.96625 23.6388 5.47625 23.2775 4.49625L23 3.75Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

              See what’s new
            </button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
