import React, { memo, useEffect, useState } from "react";
import GridMarketingCard from "./GridViewMarketingCard";
import { getSingleRoute } from "../../../../common/utils";
import LoginModal from "../../LoginModal";
import { useSelector } from "react-redux";
import editIcon from "../../../../static/images/special-edit.png";
import purpleHeartIcon from "../../../../static/images/fav-purple.svg";
import yellowHeartIcon from "../../../../static/images/yellow_heart.svg";
import { addToFavouriteReq } from "../../../services/apiService";
import { useSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';

const GridViewDataRenderingV2 = (item) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false)
  let favCourses = item.favouriteCourses;
  const user = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (user.isAuthenticated && favCourses?.length > 0) {
      const isFav = favCourses.some(subItem => {
        return subItem.course_id === item.item.course_id;
      });
      setIsFavorite(isFav);
    }
  }, [favCourses]);

  function capitalFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  const heartClick = async (item) => {
    if (user.isAuthenticated) {
      setIsFavoriteLoading(true)
      try {
        let body = {
          course_id: item.course_id,
          is_deleted: isFavorite
        }
        const { data: fav } = await addToFavouriteReq(body)
        if (fav.message) {
          enqueueSnackbar(fav.message, {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
          })
        } else if (isFavorite) {
          setIsFavorite(!isFavorite)
          enqueueSnackbar('Course removed from favourite list successfully.', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
          })
        }
        else {
          setIsFavorite(!isFavorite)
          enqueueSnackbar('Course added to favourite list successfully.', {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'center' }
          })
        }
      } catch (err) {
        console.log('err', err);
      }

      setIsFavoriteLoading(false)
    } else {
      setOpenLoginModal(true)
    }
  };
  const getRoute = (page, id) => {
    getSingleRoute(page).then((d) => {
      window.open(d + "/" + id.id)
    });
  };

  const getCourseRoute = (page, item) => {
    getSingleRoute(page).then((d) => {
      window.open(d + "/" + item.id + "/" + item.duration_id + "/" + item.course_fee_id)
    });
  };

  const handleOpenLoginModal = () => {
    if (user.isAuthenticated) {
      window.open('https://timesconsultant.com/online-consultation/', '_blank', 'rel=noopener noreferrer')
    } else {
      setOpenLoginModal(true)
    }
  };
  const handleCloseLoginModal = () => {
    setOpenLoginModal(false)
  };
  return (
    <>
      {item.item.name ? (
        <div className="courseBox">
          <div className="imgWrap">
            <img
              src="https://assets.timescoursefinder.com/Media/course-img.svg"
              alt="course-img"
            />
            <div className="courseBoxTop">
              <button
                onClick={() =>
                  getRoute("university_details", {
                    id:
                      item.item.institute_slug +
                      `/${item.item.campus.slug ? item.item.campus.slug : ""}`,
                  })
                }
              >
                <img
                  src={item.item?.logo}
                  width="100px"
                  alt={item.item.institute?.institute_name}
                />
              </button>
              <div>
                <button
                  onClick={() =>
                    getRoute("university_details", {
                      id:
                        item.item.institute_slug +
                        `/${item.item.campus.slug ? item.item.campus.slug : ""
                        }`,
                    })
                  }
                >
                  <h6>{item.item?.institute_name}</h6>{" "}
                </button>
                <p>{item?.item?.address}</p>
              </div>
            </div>
            <div className="btnGroup">
              <button
                onClick={() => item.handleModalOpen(item.item)}
                className="btn"
              >
                <img
                  src={
                    require("../../../../static/images/share-purple.svg")
                      .default
                  }
                  alt="share-purple"
                />
              </button>
              <button className="btn" onClick={() => heartClick(item.item)} disabled={isFavoriteLoading}>
                {isFavoriteLoading ?
                  <CircularProgress color="inherit" size={20} />
                  :
                  isFavorite ? (
                    <img
                      src={yellowHeartIcon}
                      alt="yellow_heart"
                    />
                  ) : (
                    <img
                      src={purpleHeartIcon}
                      alt="fav-purple"
                    />
                  )}
              </button>
            </div>
          </div>
          <div className="courseBoxCont">
            <h6>
              <b>{item.item?.name}</b>
            </h6>
            <p>
              <img
                title="Specialization"
                src={
                  require("../../../../static/images/Specialization1.svg")
                    .default
                }
                alt="Specialization1"
              />
              {item.item.specialization_name}
              <a
                href="#editModal"
                data-toggle="modal"
                title="Suggest an Edit"
                data-target="#editModal"
                onClick={() => {
                  localStorage.setItem("EditModal", "true");
                }}
              >
                <img
                  src={editIcon}
                  alt="special-edit"
                />
              </a>
            </p>

            <div>
              <div>
                <p>
                  <img
                    src={
                      require("../../../../static/images/bachelor_honors.svg")
                        .default
                    }
                    alt="bachelor_honors"
                  />
                  {item.item?.degreelevel_name} | {item.item?.coursetitle_name}{" "}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/on_campus.svg").default
                    }
                    alt="on_campus"
                  />
                  On Campus{" "}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/Amount.svg").default
                    }
                    alt="Amount"
                  />
                  {(item.item?.course_fee === 0 || item.item?.course_fee)
                    ? Math.round(item.item.course_fee) + " " + item.item.currency + "/Year"
                    : "To Be Confirmed"
                  }
                </p>
              </div>
              <div>
                <p>
                  <img
                    src={require("../../../../static/images/Years.svg").default}
                    alt="Years"
                  />

                  {item.item?.duration}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/English.svg").default
                    }
                    alt="English"
                  />
                  {capitalFirstLetter(item.item?.course_language)}
                </p>
                <p>
                  <img
                    src={
                      require("../../../../static/images/full_time.svg").default
                    }
                    alt="full_time"
                  />
                  Full Time{" "}
                </p>
              </div>
            </div>

            <div className="courseBoxFooter">
              <div className="btnGroup">
                <button
                  className="btn"
                  onClick={() =>
                    getCourseRoute("course_detail", {
                      id: item.item.course_slug,
                      duration_id: item.item?.duration_id,
                      course_fee_id: item.item?.course_fee_id
                    })
                  }
                >
                  Discover
                </button>
                <button
                  className="btn"
                  onClick={() => item.openDrawer(item.item)}
                >
                  Compare
                </button>
                <button
                  className="btn"
                  onClick={handleOpenLoginModal}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : item.item.card_type && item.item.grid_card_img_back ? (
        <GridMarketingCard detail={item.item} />
      ) : null}
      <LoginModal
        openModal={openLoginModal}
        closeModal={handleCloseLoginModal}
      />
    </>
  );
};

export default memo(GridViewDataRenderingV2);
